import React, { useEffect, useState } from 'react';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  alpha,
  Autocomplete,
  autocompleteClasses,
  InputAdornment,
  InputBase,
  Popper,
  styled,
} from '@mui/material';
import useElasticsearch from '@onemedical/elasticsearch-hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { format } from 'libphonenumber-js';
import moment from 'moment';
import uniqid from 'uniqid';

import { PatientAutocompleteOption, PatientElasticsearchResult } from './NavBar.types';
import PatientSearchResult from './PatientSearchResult';

const classes = {
  searchInput: 'searchInput',
  adornment: 'adornment',
};

type InputProps = {
  active: boolean;
};

const StyledInput = styled('div', { shouldForwardProp: (prop) => prop !== 'active' })<InputProps>(
  ({ theme, active }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),

    [`& .${classes.adornment}`]: {
      padding: theme.spacing(0, 1),
      position: 'absolute',
      fontSize: '14px',
      color: active ? theme.palette.text.secondary : theme.palette.common.white,
    },

    [`& .${classes.searchInput}`]: {
      color: theme.palette.common.white,
      fontSize: '14px',
      padding: theme.spacing(0.5, 0.5, 0.5, 4),
      transition: theme.transitions.create(['width', 'background-color', 'color', 'border']),
      borderRadius: theme.shape.borderRadius,
      width: '200px',
      '&:focus': {
        width: '360px',
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.primary,
        borderRadius: theme.shape.borderRadius,
      },
    },
  }),
);

const onelifeUrl = process.env.REACT_APP_ONELIFE_URL;
const chartUrl = process.env.REACT_APP_CLINICAL_UX_URL;
const memberManagementUrl = process.env.REACT_APP_MEMBER_MANAGEMENT_ASSETS_URL;
const maxResults = 7;
export const patientQuery = {
  query: {
    bool: {
      must: [
        {
          multi_match: {
            type: 'cross_fields',
            query: '$q',
            fields: [
              'first_name^3',
              'last_name^3',
              'nickname^3',
              'email',
              'dob_string^15',
              'phone^15',
            ],
            operator: 'and',
          },
        },
      ],
      filter: {
        term: { is_duplicate: false },
      },
      minimum_should_match: '76%',
    },
  },
};

const StyledPopper = styled(Popper)(() => ({
  [`& .onelife-container-${autocompleteClasses.paper}`]: {
    width: '400px',
    right: 0,
    position: 'absolute',
  },
  [`& .onelife-container-${autocompleteClasses.listbox}`]: {
    maxHeight: 'none',
    overflow: 'hidden',
  },
}));

function PatientSearchBox({ isAdmin }: { isAdmin: boolean }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [active, setActive] = useState(false);
  const { memberManagementUi } = useFlags();
  const allResultsLink = `${onelifeUrl}/admin/search?utf8=✓&name=${searchQuery}&service_area_id=all`;

  const [search, { results: searchResults = [], total: totalSearchResults = 0 }] =
    useElasticsearch<PatientElasticsearchResult>(patientQuery, {
      url: process.env.REACT_APP_ELASTICSEARCH_URL!,
      prefix: process.env.REACT_APP_ELASTICSEARCH_INDEX_PREFIX,
      index: 'patients',
      size: maxResults,
      variables: { q: searchQuery },
      lazy: true,
    });

  useEffect(() => {
    if (searchQuery) search({ variables: { q: searchQuery } });
  }, [search, searchQuery]);

  const mapElasticSearchResultsToOptions = () => {
    const patientResults: PatientAutocompleteOption[] = searchResults.map((result) => {
      const { nickname, first_name, phone, email, id, display_dob, last_name, dob } = result;
      const formatted_age = `${moment().diff(moment(dob), 'years')} yo`;
      const displayFirstName = nickname ? `${nickname} (${first_name})` : first_name;
      const contactInfo = phone.includes(searchQuery.replace(/-|\(|\)|\s/g, ''))
        ? format(phone[0], 'US', 'National')
        : email && email.join(', ');

      let link = `${chartUrl}/#/patients/${id}/chart/summaries/new`;
      if (isAdmin && memberManagementUi) {
        link = `${memberManagementUrl}/account/${id}/`;
      } else if (isAdmin) {
        link = `${onelifeUrl}/${id}/account`;
      }

      return {
        key: uniqid(),
        id,
        display_dob,
        title: `${displayFirstName} ${last_name}`,
        description: `${display_dob} (${formatted_age}) · ${contactInfo}`,
        link,
      };
    });

    return totalSearchResults > maxResults
      ? patientResults.concat([
          {
            key: uniqid(),
            title: 'View All Results',
            link: allResultsLink,
          },
        ])
      : patientResults;
  };

  const options = mapElasticSearchResultsToOptions();

  const onChange = (
    _: React.ChangeEvent<unknown>,
    data: PatientAutocompleteOption | string | null,
  ) => {
    // freesolo prop on Autocomplete causes this to sometimes be a string
    if (!data || typeof data === 'string') return;
    window.open(data.link, '_blank', 'noopener noreferrer');
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && searchQuery) {
      window.open(allResultsLink, '_blank', 'noopener noreferrer');
    }
  };

  return (
    <Autocomplete
      autoComplete
      fullWidth
      freeSolo
      open={active}
      PopperComponent={StyledPopper}
      onKeyPress={handleKeyPress}
      options={options}
      getOptionLabel={() => searchQuery}
      onChange={onChange}
      onFocus={() => setActive(true)}
      onBlur={() => setActive(false)}
      onInputChange={(_, value) => setSearchQuery(value)}
      inputValue={searchQuery}
      data-cy="patient-search"
      renderOption={(props, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...props} key={option.key}>
          <PatientSearchResult option={option} searchQuery={searchQuery} />
        </li>
      )}
      renderInput={({ InputProps: { ref }, inputProps }) => (
        <StyledInput active={active}>
          <InputBase
            startAdornment={
              <InputAdornment className={classes.adornment} position="start">
                <FontAwesomeIcon icon={faSearch} />
              </InputAdornment>
            }
            ref={ref}
            placeholder={active ? 'Search by name, DOB, phone, or email' : 'Patient search'}
            classes={{ input: classes.searchInput }}
            inputProps={{ ...inputProps, autoComplete: 'new-password' }}
            data-cy="patient-search-input"
          />
        </StyledInput>
      )}
    />
  );
}

export default PatientSearchBox;
