import { useLDClient } from 'launchdarkly-react-client-sdk';
import { InternalUser } from '../models/InternalUser';

export interface LaunchDarklyHook {
  identify(user: InternalUser): void;
}

export function useLaunchDarkly(): LaunchDarklyHook {
  const client = useLDClient();
  function identify(user: InternalUser): void {
    if (client) {
      const roles = user.roles.map((role) => role.name);
      client.identify({
        kind: 'user',
        key: user.id,
        roles,
      });
    }
  }
  return {
    identify,
  };
}
