import { gql } from '@apollo/client';
import { useState } from 'react';
import { InternalUser } from '../models/InternalUser';
import { Role } from '../models/Role';
import { useZendesk } from './Zendesk';

export const PendoUserFields = gql`
  fragment PendoUserFields on InternalUser {
    displayName
    lastName
    email
  }
`;

export interface Pendo {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialize(options: any): void;
}

function getVisitorRoles(roles: Role[]): string[] {
  return roles
    .map((role) => role.name.toLowerCase())
    .map((role) => (role === 'virtual medical team / vmt' ? 'clinical support team' : role))
    .map((role) => role.replaceAll(' ', '_'));
}

function getVisitorTags(roles: Role[]): string[] {
  const names = roles.map((role) => role.name.toLowerCase());
  if (names.includes('provider') && names.includes('virtual medical team / vmt')) {
    return ['vmt provider'];
  }
  if (names.includes('provider')) {
    return ['in-office provider'];
  }
  if (names.includes('phlebotomist')) {
    return ['phleb'];
  }
  if (names.includes('admin')) {
    return ['admin'];
  }
  return [];
}

function initPendo(user: InternalUser): void {
  window.pendo.initialize({
    account: {
      id: 'Internal Users',
    },
    visitor: {
      id: user.id,
      email: user.email,
      full_name: user.displayName,
      role: getVisitorRoles(user.roles),
      tags: getVisitorTags(user.roles),
    },
    // ensure pendo doesn't attempt to send any PII text from the DOM.
    // we have this enabled at the subscription level but this makes
    // it explicitly clear for developers.
    excludeAllText: true,
  });
}

function isAutomatedTestingAccount(user: InternalUser): boolean {
  const lastNameStaging = user.lastName.includes('staging');
  const hasOneMedicalEmail = user.email.includes('onemedical.com');
  return lastNameStaging && hasOneMedicalEmail;
}

function injectPendoScript(): void {
  const key = process.env.REACT_APP_PENDO_API_KEY;
  const el = document.createElement('script');
  el.type = 'text/javascript';
  el.innerHTML = `
    (function(apiKey) {
      (function(p, e, n, d, o) {
        var v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (w = 0, x = v.length; w < x; ++w)(function(m) {
          o[m] = o[m] || function() {
            o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
          };
        })(v[w]);
        y = e.createElement(n);
        y.async = !0;
        y.src = 'https://content.pendo.ui.1life.com/agent/static/' + apiKey + '/pendo.js';
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');
    })('${key}');
  `;
  document.head.appendChild(el);
}

export interface PendoHook {
  initialize(zendeskToken: string, user: InternalUser): void;
}

export function usePendo(): PendoHook {
  const zendesk = useZendesk();
  const [initialized, setInitialized] = useState(false);
  function initialize(zendeskToken: string, user: InternalUser): void {
    const key = process.env.REACT_APP_PENDO_API_KEY;
    if (key && !isAutomatedTestingAccount(user) && !initialized) {
      injectPendoScript();
      zendesk.initialize(zendeskToken);
      initPendo(user);
      setInitialized(true);
    }
  }
  return {
    initialize,
  };
}
