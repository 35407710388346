import { Button, styled } from '@mui/material';

const NavButton = styled(Button)(() => ({
  padding: '10px 16px',
  fontSize: '14px',
  borderRadius: 0,
  whiteSpace: 'nowrap',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    color: 'white',
  },
  '&.active': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
})) as typeof Button;

export default NavButton;
