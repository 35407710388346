import mixpanelBrowser from 'mixpanel-browser';

const enabled = !!process.env.REACT_APP_MIXPANEL_TOKEN;

if (enabled) mixpanelBrowser.init(process.env.REACT_APP_MIXPANEL_TOKEN!);

const mixpanel = {
  identify: (id: string) => {
    if (enabled) mixpanelBrowser.identify(id);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  track: (name: string, props: any) => {
    if (enabled) mixpanelBrowser.track(name, { application: 'onelife-container', ...props });
  },
};

export default mixpanel;
