import { Menu, styled } from '@mui/material';

const NavMenu = styled(Menu)(({ theme }) => ({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  zIndex: `${theme.zIndex.tooltip}!important` as any,
  '& a, & span, & div, & li': {
    fontSize: '14px',
    '&:hover': {
      color: theme.palette.text.primary,
    },
    '&.active': {
      backgroundColor: theme.palette.action.selected,
    },
  },
}));

export default NavMenu;
