import { useState } from 'react';

function injectZendeskScript(): void {
  const key = '36992a6c-1f30-43f4-b3f3-7b11c6293b28';
  const el = document.createElement('script');
  el.id = 'ze-snippet';
  el.src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
  document.head.appendChild(el);
}

export interface ZendeskHook {
  initialize(token: string): void;
}

export function useZendesk(): ZendeskHook {
  const [initialized, setInitialized] = useState(false);
  function initialize(token: string) {
    if (!initialized && token) {
      window.zESettings = {
        webWidget: {
          authenticate: {
            jwtFn(callback) {
              callback(token);
            },
          },
        },
      };
      injectZendeskScript();
      setInitialized(true);
    }
  }
  return {
    initialize,
  };
}
