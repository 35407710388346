import { MouseEvent, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  faAngleDown,
  faHandHeart,
  faHeadSideBrain,
  faMoonStars,
  faPhoneSlash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItemIcon, ListItemText, MenuItem, styled } from '@mui/material';
import { useAuthentication } from '@onemedical/auth';
import { useFlags } from 'launchdarkly-react-client-sdk';

import roles from '../data/roles';
import NavButton from './NavButton';
import NavMenu from './NavMenu';

const UPDATE_PROFILE = gql`
  mutation UpdateProfile($roleIds: [ID!]!) {
    updateProfile(input: { roleIds: $roleIds }) {
      success
      errors
      profile {
        id
        roles {
          id
        }
      }
    }
  }
`;

const StyledIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const StyledListItemIcon = styled(ListItemIcon)(() => ({
  minWidth: '32px',
}));

const mapIdsToString = (ids: (number | undefined)[]) =>
  ids.filter((id): id is number => !!id).map((id) => id.toString());

interface UserMenuProps {
  displayName: string;
  currentRoles?: {
    id: number;
  }[];
}

interface FrontDeskMenuItemProps {
  isFrontDeskAdmin: boolean;
  onDisable: () => void;
  onEnable: () => void;
}

function FrontDeskMenuItem({ isFrontDeskAdmin, onDisable, onEnable }: FrontDeskMenuItemProps) {
  if (isFrontDeskAdmin) {
    return (
      <MenuItem data-cy="front-desk-toggle" onClick={onDisable}>
        Disable Front Desk Mode
      </MenuItem>
    );
  }
  return (
    <MenuItem data-cy="front-desk-toggle" onClick={onEnable}>
      <StyledListItemIcon>
        <FontAwesomeIcon icon={faPhoneSlash} />
      </StyledListItemIcon>
      <ListItemText>Front Desk Mode</ListItemText>
    </MenuItem>
  );
}

function UserMenu({ displayName, currentRoles }: UserMenuProps) {
  const [logout] = useAuthentication();
  const { workflowHelpOutMode, removeFrontDeskMode } = useFlags();

  const [updateProfile] = useMutation(UPDATE_PROFILE, {
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error('Profile update failed:', error);
    },
  });

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const openProfileMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentRoleIds = currentRoles?.map((r) => r?.id) || [];
  const oneLifeUrl = process.env.REACT_APP_ONELIFE_URL;
  const currentRolesWithout = (toRemove: number) =>
    mapIdsToString(currentRoleIds.filter((id) => id !== toRemove));
  const canEnableVMTAfterHours =
    currentRoleIds.includes(roles.VMT) || currentRoleIds.includes(roles.RN);
  const canEnableHelpOutMode =
    workflowHelpOutMode &&
    (currentRoleIds.includes(roles.VMT) || currentRoleIds.includes(roles.RN));
  const isFrontDeskAdmin =
    currentRoleIds.includes(roles.ADMIN) && currentRoleIds.includes(roles.FRONT_DESK_ADMIN);
  const canEnableFrontDeskMode = !removeFrontDeskMode && currentRoleIds.includes(roles.ADMIN);

  return (
    <>
      <NavButton
        color="inherit"
        onClick={openProfileMenu}
        data-cy="user-menu-trigger"
        endIcon={<FontAwesomeIcon icon={faAngleDown} />}
      >
        {displayName}
        {canEnableFrontDeskMode && isFrontDeskAdmin && (
          <StyledIcon icon={faPhoneSlash} color="#FBDD08" />
        )}
        {currentRoleIds.includes(roles.VMT) && currentRoleIds.includes(roles.VMT_FOCUS) && (
          <StyledIcon data-cy="focus-mode-icon" icon={faHeadSideBrain} color="#FBDD08" />
        )}
        {canEnableVMTAfterHours && currentRoleIds.includes(roles.VMT_AFTER_HOURS) && (
          <StyledIcon icon={faMoonStars} color="#FBDD08" />
        )}
        {canEnableHelpOutMode && currentRoleIds.includes(roles.HELP_OUT_MODE) && (
          <StyledIcon data-cy="help-out-mode-icon" icon={faHandHeart} color="#FBDD08" />
        )}
      </NavButton>
      <NavMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        data-cy="user-menu"
        disableScrollLock
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {canEnableFrontDeskMode && (
          <FrontDeskMenuItem
            isFrontDeskAdmin={isFrontDeskAdmin}
            onDisable={() =>
              updateProfile({
                variables: {
                  roleIds: currentRolesWithout(roles.FRONT_DESK_ADMIN),
                },
              })
            }
            onEnable={() =>
              updateProfile({
                variables: {
                  roleIds: mapIdsToString([...currentRoleIds, roles.FRONT_DESK_ADMIN]),
                },
              })
            }
          />
        )}
        {currentRoleIds.includes(roles.VMT) &&
          (currentRoleIds.includes(roles.VMT_FOCUS) ? (
            <MenuItem
              onClick={() =>
                updateProfile({
                  variables: { roleIds: currentRolesWithout(roles.VMT_FOCUS) },
                })
              }
            >
              Disable Focus Mode
            </MenuItem>
          ) : (
            <MenuItem
              data-cy="enable-focus-mode"
              onClick={() =>
                updateProfile({
                  variables: {
                    roleIds: [
                      ...currentRolesWithout(roles.VMT_AFTER_HOURS),
                      roles.VMT_FOCUS.toString(),
                    ],
                  },
                })
              }
            >
              <StyledListItemIcon>
                <FontAwesomeIcon icon={faHeadSideBrain} />
              </StyledListItemIcon>
              <ListItemText>Focus Mode</ListItemText>
            </MenuItem>
          ))}
        {canEnableHelpOutMode &&
          (currentRoleIds.includes(roles.HELP_OUT_MODE) ? (
            <MenuItem
              onClick={() =>
                updateProfile({
                  variables: { roleIds: currentRolesWithout(roles.HELP_OUT_MODE) },
                })
              }
            >
              Disable Help Out Mode
            </MenuItem>
          ) : (
            <MenuItem
              data-cy="enable-help-out-mode"
              onClick={() =>
                updateProfile({
                  variables: {
                    roleIds: [
                      ...currentRolesWithout(roles.HELP_OUT_MODE),
                      roles.HELP_OUT_MODE.toString(),
                    ],
                  },
                })
              }
            >
              <StyledListItemIcon>
                <FontAwesomeIcon icon={faHandHeart} />
              </StyledListItemIcon>
              Help Out Mode
            </MenuItem>
          ))}
        {canEnableVMTAfterHours &&
          (currentRoleIds.includes(roles.VMT_AFTER_HOURS) ? (
            <MenuItem
              onClick={() =>
                updateProfile({
                  variables: { roleIds: currentRolesWithout(roles.VMT_AFTER_HOURS) },
                })
              }
            >
              Disable After Hours Mode
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() =>
                updateProfile({
                  variables: {
                    roleIds: [
                      ...currentRolesWithout(roles.VMT_FOCUS),
                      roles.VMT_AFTER_HOURS.toString(),
                    ],
                  },
                })
              }
            >
              <StyledListItemIcon>
                <FontAwesomeIcon icon={faMoonStars} />
              </StyledListItemIcon>
              <ListItemText>After Hours</ListItemText>
            </MenuItem>
          ))}
        <MenuItem component="a" href={`${oneLifeUrl}/admin/my_admin_account`} target="_self">
          My Account
        </MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </NavMenu>
    </>
  );
}

export default UserMenu;
